import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';

function useCompareProductsCount() {
  return useQuery({
    queryKey: ['compare-count'],
    queryFn: ApiCompare.getCompareProductsCount,
    placeholderData: (previousData) => previousData,
  });
}

export default useCompareProductsCount;
