import { useQuery } from '@tanstack/react-query';

import * as ApiMeta from '@Api/Meta';
import useRequest from '@Hooks/useRequest';

import type { MetaData } from '@Types/Meta';
import type { UseQueryResult } from '@tanstack/react-query';

function useMeta(): UseQueryResult<MetaData> {
  const { region, secondaryRegion, language, queryClient, targetRegion, targetSecondaryRegion } =
    useRequest();
  const keys = [
    'meta',
    {
      region: targetRegion || region,
      secondaryRegion: targetSecondaryRegion || secondaryRegion,
      lang: language.id,
    },
  ];

  const query = useQuery({
    queryKey: keys,
    queryFn: () => {
      const cache = queryClient.getQueryData<MetaData>(keys);
      if (cache) return Promise.resolve(cache);

      return ApiMeta.fetch();
    },
    enabled: true,
    placeholderData: (previousData) => previousData,
  });

  return query;
}

export default useMeta;
