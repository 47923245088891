import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import useDeps from '@Contexts/DI/useDeps';

import type { UseQueryResult } from '@tanstack/react-query';
import type { VisitShoroomData } from '@Types/Visits';

/**
 * NOTE: Данный запрос возвращает персонализированную информацию, поэтому он не должен выполняться во время серверного рендера,
 * т.к. это лишает нас возможности кэшировать страницы для улучшения производительности
 */

export function useVisit(interval?: number): UseQueryResult<VisitShoroomData> {
  const { cabinetApi } = useDeps();
  const { slug } = useParams();
  const keys = ['page', 'visit', slug];

  return useQuery({
    queryKey: keys,
    queryFn: () => cabinetApi.fetchCRMVisit(slug),
    placeholderData: (previousData) => previousData,
    ...(interval && { refetchInterval: interval }),
  });
}
