import { EventListener } from '@Events/EventListener';
import * as ApiCompare from '@Api/Compare';

import type { ComparePayload } from '@Queries/Compare/useCompare';
import type { ResultData } from '@Api/Compare/addProduct';
import type { QueryClient } from '@tanstack/react-query';
import type { CompareData } from '@Types/Compare';
import type AnalyticsEventDispatcher from '@Infrastructure/details/analytics/AnalyticsEventDispatcher';

export type CompareEventData = {
  (eventName: 'compare.add', data: AddEventData): void;
  (eventName: 'compare.remove', data: RemoveEventData): void;
};
export type RemoveEventData = {
  productId: number;
  payload?: ComparePayload;
  onSuccess?: (data: ResultData, variables?: unknown, context?: unknown) => void;
};

export type AddEventData = {
  productId: number | number[];
  payload?: ComparePayload;
  onSuccess?: (data: ResultData, variables?: unknown, context?: unknown) => void;
};

export class CompareListener extends EventListener {
  private queryClient: QueryClient;

  private analytics: AnalyticsEventDispatcher;

  constructor(deps: { queryClient: QueryClient; analytics: AnalyticsEventDispatcher }) {
    super();
    this.queryClient = deps.queryClient;
    this.analytics = deps.analytics;
    this.on('compare.add', this.add.bind(this));
    this.on('compare.remove', this.remove.bind(this));
  }

  add(eventData: AddEventData) {
    const analyticsData = eventData?.payload?.analytics;
    const hash = eventData?.payload?.hash;
    const parameterValues = eventData?.payload?.parameterValues;

    this.queryClient
      .getMutationCache()
      .build(this.queryClient, {
        mutationFn: () => ApiCompare.addProduct(eventData.productId, hash, parameterValues),
        onSuccess: (data) => {
          this.queryClient.setQueryData(['compare'], data);
          this.queryClient.setQueryData(['compare-count'], {
            productCount: data.products.length,
          });

          if (analyticsData) {
            this.analytics.dispatchEvent('favorite.add', analyticsData);
          }

          if (eventData.onSuccess) eventData.onSuccess(data);
        },
      })
      .execute(null);
  }

  remove(eventData: RemoveEventData) {
    const hash = eventData?.payload?.hash;
    this.queryClient
      .getMutationCache()
      .build(this.queryClient, {
        mutationFn: () => ApiCompare.deleteProduct(eventData.productId, hash),
        onSuccess: (data) => {
          const previousData = this.queryClient.getQueryData<CompareData>(['compare']);
          const product = previousData?.products.find((item) => item.id === eventData.productId);

          if (product) {
            this.analytics.dispatchEvent('favorite.remove', { product });
          }

          this.queryClient.setQueryData(['compare'], data);
          this.queryClient.setQueryData(['compare-count'], {
            productCount: data.products.length,
          });

          if (eventData.onSuccess) eventData.onSuccess(data);
        },
      })
      .execute(null);
  }
}
