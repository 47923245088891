import { useMemo, useRef } from 'react';
import equal from 'fast-deep-equal';

import * as Cart from '@Queries/Cart';
import * as RelatedProducts from '@Stores/RelatedProducts';
import generateHash from '@Utils/generateHash';

import type { ParameterValueData } from '@Types/Cart';

export interface HasInCartData {
  productId: number;
  parameters?: ParameterValueData[];
  relatedProducts?: boolean;
  isModified?: boolean;
}

export interface SelectedProductsData {
  id: number;
  quantity?: number;
  parameters?: ParameterValueData[];
}

/**
 * Товар уже есть в корзине?
 * @param productId — идентификатор товара
 * @param parameters - набор параметров товара, для отслеживания уникальности
 * @param relatedProducts - включаем, когда требуется проверить сопутствующие товары в корзине
 */

function useHasInCart(params: HasInCartData) {
  const { productId, parameters, relatedProducts, isModified } = params;
  const cart = Cart.useCart();
  const selectedRelatedProducts = RelatedProducts.useSelected();
  const defaultParameterValues = useRef(parameters);

  const isModifiedParameters = useMemo(() => {
    if (isModified) return true;
    return !equal(defaultParameterValues.current, parameters);
  }, [parameters, defaultParameterValues, isModified]);

  const products = useMemo<SelectedProductsData[]>(
    () => [
      {
        id: productId,
        parameters: parameters && isModifiedParameters ? parameters : [],
        quantity: 1,
      },
    ],
    [productId, parameters, isModifiedParameters],
  );

  if (relatedProducts) {
    (selectedRelatedProducts || []).forEach((product) => {
      products.push({
        id: product.id,
        quantity: product.quantity,
        parameters: [],
      });
    });
  }

  const hasInCart = useMemo<boolean>(() => {
    if (!cart.data) return false;

    return products.every((product) => {
      const hash = generateHash({
        ...product,
        quantity: null,
        shopProducts: [],
        fabricValues: null,
      });

      return [...cart.data.positions, ...cart.data.newPositions].some((position) => {
        const productsHash = position.hash?.split('@') || [];
        return productsHash.includes(hash);
      });
    });
  }, [products, cart.data]);

  return hasInCart;
}

export default useHasInCart;
