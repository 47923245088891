import { useQuery } from '@tanstack/react-query';

import * as ApiCompare from '@Api/Compare';

import type { ProductData } from '@Types/Product';

function useInCompare(id: ProductData['id'], hash?: string) {
  return useQuery({
    queryKey: ['compare'],
    queryFn: ApiCompare.getCompareProducts,
    notifyOnChangeProps: ['data'],
    placeholderData: { products: [] },
    select: ({ products }) =>
      products.some((product) => {
        if (product?.hash) return product.hash === hash && product.id === id;

        return product.id === id;
      }),
  });
}

export default useInCompare;
